<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row mr>

        <e-col style="min-width: 45%;">
          <date-input label="Data de Entrada (De)" v-model="filters.data1"/>
        </e-col>

        <e-col style="min-width: 45%;">
          <date-input label="Data de Entrada (Até)" v-model="filters.data2"/>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="ID do Leilão"
          >
            <erp-input v-model="filters.id" />
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Código do Leilão"
          >
            <erp-input v-model="filters.codigo" />
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 98%">
          <comitente-select multiple :columns="[
                      {label: 'Nome', value: 'label'},
                      {label: 'Tipo', value: 'tipoName'}
                    ]" v-model="filters.comitentes" />
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Tipo de arquivo"
          >
            <erp-select size="1" placeholder="Selecione o tipo do arquivo" :options="tipos" v-model="filters.tipo" />
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn @click="gerar(formato)" :loading="loading" label="Gerar" icon="print" icon-type="fa" icon-style="light" flat no-caps/>
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowFooter,
  ErpSField,
  ErpInput,
  ErpSelect
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import mixinPrint from "@/components/relatorios/components/mixinPrint"
import {relatorio} from "@/domain/relatorios/services"
import createPrint from "@/components/relatorios/components/customPrint/createPrint"
import ComitenteSelect from "@/components/comitente/helpers/input/ComitenteSelect"
import DateInput from "@/reuse/input/Date"
import {datePtToEn} from "@/utils/date"
import {date} from "uloc-vue"
import {listTiposArquivo} from '@/domain/bem/services'

let filters = {
  id: null,
  codigo: null,
  data1: null,
  data2: null,
  comitentes: [],
  tipo: null
}

export default {
  name: 'AnaliseBensSemArquivoWindow',
  mixins: [mixinPrint],
  props: {
    router: {required: true},
    leilaoId: {required: false}
  },
  data() {
    let f = JSON.parse(JSON.stringify(filters))
    if (this.leilaoId) {
      f.id = this.leilaoId
    }
    return {
      tipos: [],
      formato: 'html',
      filters: f
    }
  },
  mounted() {
    this.loadTipos()
  },
  computed: {},
  methods: {
    loadTipos () {
      listTiposArquivo(200, 1) // TODO: NO LIMIT
          .then(response => {
            this.tipos = response.data.result ? response.data.result.map(item => {
              return {
                ...item,
                label: item.nome,
                value: item.id
              }
            }) : []
          })
          .catch((error) => {
            this.alertApiError(error)
          })
    },
    createPrint: createPrint,
    actionPrint (action) {
      console.log('Print Action ', action)
      this.gerar(action)
    },
    gerar(formato = 'html') {
      let filters = JSON.parse(JSON.stringify(this.filters))

      if (!filters.tipo) {
        this.$uloc.dialog({
          className: 'erp-confirm c-error',
          title: 'Preenchimento inválido',
          color: 'info',
          message:'Preencha tipo do arquivo'
        })
        return
      }

      let data1, data2
      if (filters.data1) {
        data1 = datePtToEn(filters.data1)
        data2 = datePtToEn(filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        filters.data1 = data1
        filters.data2 = data2
      }

      this.loading = true
      relatorio('analiseAusenciaArquivoBem', filters, formato)
          .then(response => {
            this.loading = false
            formato === 'html' && this.createPrint(response.data, () => import('@/components/relatorios/components/customPrint/BemListPrint'), 'analise', 'Análise de Ausência de Arquivos de Bem', 'landscape')
          })
          .catch(error => {
            this.loading = false
            if (formato === 'html') {
              this.alertApiError(error)
            } else{
              error.data.text().then(text => {
                this.alertApiError({data: JSON.parse(text)})
              })
            }
          })
    }
  },
  components: {
    ECol,
    ERow,
    WindowFooter,
    WindowContent,
    ErpSField,
    ErpInput,
    ComitenteSelect,
    DateInput,
    ErpSelect
  }
}
</script>
